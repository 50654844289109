/*
COLLOR PALLETTE 
https://coolors.co/009db5-ffe1a8-ffa50a-e26d5c-472d30
  Blue Munsell    - #009DB5
  Peach           - #FFE1A8
  Terra Cotta     - #E26D5C
  Catawba         - #723D46
  Old Burgundy    - #472D30
*/


/* GLOBAL STYLES
--------------------------------------*/
html{
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  /* background-color: #FFF3B0; */
  color: #000000;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  line-height: 1.5;
}

h1{
  font-size: 42px;
  line-height: 1;
}

h2{
  font-size: 32px;
}

/*  */
.sketch_background{
  position: fixed;
  z-index: -1;
}

.content_wrap{
  text-align: center;
  margin: 0 auto;
  padding: 60px 0; 
}

.title{
  grid-column: 2 / 5;
  grid-row: 1 / 5;
}

.text{
  grid-column: 1/5;
  grid-row: 2;
}

.subtitle {
  grid-column: 2/ 5;
  grid-row:2;
}

.P5_sketch {
  text-align: center;
  padding: 0;
  margin: 0;
  grid-column: 1;
  grid-row: 1 / 5;
}

